import Image from "next/image";

const Location = () => {
  return (
    <section className='flex flex-col py-24 relative'>
      <div className='block absolute inset-0 opacity-25'>
        <Image
          alt='Latest information and technology at Soluna London'
          src='/new-room.png'
          className='object-cover'
          quality={50}
          fill
          sizes="100vw" />
      </div>
      <div className='relative z-10 w-full'>
        <div className='flex justify-center px-4'>
          <iframe
            data-hj-allow-iframe=""
            title="Soluna London"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d318766.41995049594!2d-0.2739964584921436!3d51.37801248392748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8ab0441e3b0f9%3A0xa1fb86dd82bc5ef6!2sSoluna%20London!5e0!3m2!1sen!2suk!4v1730237267304!5m2!1sen!2suk"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
            className='w-full max-w-screen-lg shadow-md rounded-md'
          />
        </div>
      </div>
    </section>
  );
}

export default Location
